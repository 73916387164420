@font-face {
  font-family: 'PicNic';
  src: local('PicNic'), url(./fonts/PicNic-Regular.woff) format('woff');
}

@font-face {
  font-family: 'PSL';
  src: local('PicNic'), url(./fonts/PlantasiaStingray-Light.otf) format('opentype');
}
@font-face {
  font-family: 'PSH';
  src: local('PicNic'), url(./fonts/PlantasiaStingray-Heavy.otf) format('opentype');
}

@font-face {
  font-family: 'DM';
  src: local('PicNic'), url(./fonts/DeterminationMono.ttf) format('truetype');
}


#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: 'DM';
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  background: black;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.App canvas {
  width: 100%;
  height: 100%;
}

.THREE {
  position: absolute;
  touch-action: none;
  width: 100%;
  height: 100%;
}

@keyframes blobs{
  0% {
    color: hsl(200, 40%, 80%);
    transform: translate(-50%, -50%) scale(1.02);
  }
  50% {
    color: hsl(260, 40%, 80%);
    transform: translate(-50%, -50%) scale(.98);
  }
  100% {
    color: hsl(200, 40%, 80%);
    transform: translate(-50%, -50%) scale(1.02);
  }
}

.LOADING-FULL {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1.0);
  z-index: 9999;
}

.loading-text {
  color: white;
  font-size: 5vh;
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 5px pink;
}

.LOADING {
  height: 30vh;
  font-size: 20vmin;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: blobs 1s linear infinite;
}

.LOADING img {
  max-width: 100%;
  max-height: 100%;
}

.sc-player {
  z-index: 999;
  /*width: 100vw;
  height: 10vh;*/
  position: absolute;
  bottom: 100%;
  left: 0;
/*  display: none;*/
}

#turbFilter {
/*  mix-blend-mode: multiply;*/
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
/*  filter: url(#filter);*/
}

#text {
  z-index: -1;
  top: 50%;
  left: 50%;
  position: absolute;
  width: 50%;
  height: 50%;
  font-size: 200px;
  transform: translate(-50%, -50%);
}

.small {
  position: absolute;
  top: 0;
  left: 0;
      font: italic 300px sans-serif;
    }


    .annotationParent > div > div {
  max-width: 40%;
}

.annotationParent {
   zoom: 2;
    scale: 0.5;
    transform-origin: top left;
}

.trackTitle {
  font-size: 2px;
/*  font-weight: 600;*/
  color:  hsla(.2, 90%, 100%, .5);
/*  background: white;*/
  transition: font-size .5s;
}

.hidden {
  opacity: 0;
  pointer-events: none;
/*  background-color: rgba(0, 0, 0, 0);*/
  transition: opacity 1s;
/*  animation: fadeOut 1s 1s forwards;*/
}


/*
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .trackTitleContainer {
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    background-color: rgba(255, 255, 255, 0.5);  
  }
}*/



/*@media (min-aspect-ratio: 1) {*/

  .INFO-block1 {
    position: absolute;
    width: 100%;
    height: 50%;
  }

  .INFO-block2 {
    position: absolute;
    width: 100%;
    height: 13%;
    bottom: 3%;
    text-align: left;
    left: 3%;
  }

  /*.THREE {
    width: 75%;
    height: 100%;
  }*/

  .INFO {
    position: absolute;
    left: 0%;
    height: 100%;
    width: 100%;

   pointer-events: none;
/*    background: hsl(180, 20%, 95%);*/
/*    box-shadow: inset 1px 0px 20px hsl(180, 40%, 20%);*/

    font-size: 2.2vw;
/*    color: hsl(0, 90%, 90%);*/
    color: white;
    text-shadow: 2px 2px 5px pink;
  }

  .INFO .main-logo {
    position: absolute;
    bottom: 40px;
    height: 320%;
/*    width: 80%;*/
    left: 50%;
    transform: translateX(-50%);
    -webkit-filter: drop-shadow(5px 5px 7px pink);
    filter: drop-shadow(5px 5px 7px pink);
  }

  .TITLE {
    width: 80%;
    font-size: 4vmax;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }

  .TrackInfo {
    font-size: 2.5vw;
    width: 95%;
    position: absolute;
    bottom: 0vw;
    left: 0%;
/*    font-family: 'DM';*/
  }

  .Playing {
    font-size: 2.5vw;
    width: 90%;
    position: absolute;
/*    top: 10%;*/
    bottom: 3.3vw;
    left: 0%;
/*    transform: translateX(-50%);*/
    color: hsl(180, 90%, 90%);
    text-shadow: 2px 2px 5px turquoise;
  }

/*}*/

.LINKS {
    position: absolute;
    top: 14%;
    left: 1%;
/*    transform: translatex(-50%);*/
    height: 3.3vh;
    width: 18%;
    pointer-events: auto;
  }

  .LINKS svg {
/*    fill: hsl(0, 90%, 90%);*/
    fill:white;
    height: 100%;
    margin-left: 1%;
    margin-right: 1%;
    filter: drop-shadow(3px 3px 5px pink);
  }


@media (max-aspect-ratio: 1) {
  
  .LINKS {
    top: 3%;
    left: 2%;
    height: 3.7vh;
    width: 94%;
    text-align: right;
  }

  .INFO .main-logo {
    position: absolute;
    height: 250%;
    top: 0;
    width: auto;
    left: 5%;
    transform: translateY(-25%);
  }

  .TrackInfo {
    font-size: 4vw;
    bottom: 0vw;
  }

    .Playing {
    font-size: 4vw;
    bottom: 5vw;
  }

}